
//TODO: clean up this mess of a package
// 1. Split out most of the search functionality into separate services
//    a. Sort + Query should be together
//    b. The query builder should be a separate component (and should support async actions)
//        i. It should provide the input bar, and needs access to the job, because it may add cases/controls
//        ii. It should expose a function to add cases/controls through a separate service, which will be re-used
//            for the toolbar button trigger of that (the default trigger will be typing cases and not having cases entered)

/*@ngInject*/ function ElasticSearchSuggest($log, $window, $location, $http, $q,
  $routeParams, $anchorScroll, $mdDialog, _, SETTINGS) {
}

angular.module('sq.jobs.results.search.suggest.component', [])

  .component('sqSuggest', {
    bindings: {
      job: '<',
      query: '<',
      onSuggested: '&',
    }, // isolate scope
    template: `<span>
  <span class='suggestion-box' ng-repeat='suggestion in $ctrl.suggestions track by $index'>
    <span ng-click='$ctrl.submitQueryChange(suggestion[0])'>{{suggestion}}</span>
  </span>
  </span>`,
    controller: ElasticSearchSuggest,
  });